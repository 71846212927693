import React from 'react';
import MultiSubSidebarContainer from '/client/app/components/communitySidebar/multiSubSidebarContainer';
import PostImpressionsWidget from '/client/app/components/postImpressionsWidget/postImpressionsWidget';
import TagStatisticsWidget from '/client/app/components/tagStatisticsWidget/tagStatisticsWidget';

interface IProps {
	communityName: string;
}

export default function PostSidebar(props: IProps) {
	const { communityName } = props;
	return (
		<div className="postSidebar">
			<PostImpressionsWidget />
			<br />
			<TagStatisticsWidget />
			<br />
			<MultiSubSidebarContainer subNames={[communityName]} />
		</div>
	);
}
