import React from 'react';
import PropTypes from 'prop-types';
import Loading from './loading';
import isFunction from '/client/app/util/isFunction';

interface IProps {
	loading: boolean;
	error?: Error;
	count?: number;
	children: any;
	width?: string;
	height?: string;
}

export default function LoadingWrapper(props: IProps) {
	const { loading, error, children, width, height, count } = props;
	if (loading) {
		const result = [];
		for (let i = 0; i < (count || 1); i++) {
			result.push(<Loading width={width} key={i} height={height} />);
		}
		return result;
	}
	if (error) {
		return <span className="error">{error.message}</span>;
	}
	return isFunction(children) ? children() : children;
}

LoadingWrapper.propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.instanceOf(Error),
	count: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	children: PropTypes.any,
	width: PropTypes.string,
	height: PropTypes.string,
};

LoadingWrapper.defaultProps = {
	count: 1,
	width: undefined,
	height: undefined,
	error: undefined,
};
