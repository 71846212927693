import React from 'react';
import PropTypes from 'prop-types';

import './placeholderWidget.scss';

interface IProps {
	text: string;
}

export default function PlaceholderWidget(props: IProps) {
	const { text } = props;
	return (
		<div className="placeholderWidget">
			<div className="placeholderWidgetInner">{text}</div>
		</div>
	);
}

PlaceholderWidget.propTypes = {
	text: PropTypes.string.isRequired,
};
