const MINUTE = 60000;
const HOUR = 3600000;
const DAY = 86400000;
const WEEK = 604800000;
const MONTH = 2592000000;
const YEAR = 31557000000;

export default function getTimeDelta(oldDate: Date, suffix = ' ago'): String {
	const currentDate = new Date();
	const dateDiff = currentDate.getTime() - oldDate.getTime();
	if (dateDiff < MINUTE) {
		return `less than 1 minute${suffix}`;
	}
	if (dateDiff < HOUR) {
		const minutes = Math.floor(dateDiff / MINUTE);
		if (minutes === 1) {
			return `${minutes} minute${suffix}`;
		}
		return `${minutes} minutes${suffix}`;
	}
	if (dateDiff < DAY) {
		const hours = Math.floor(dateDiff / HOUR);
		if (hours === 1) {
			return `${hours} hour${suffix}`;
		}
		return `${hours} hours${suffix}`;
	}
	if (dateDiff < WEEK) {
		const days = Math.floor(dateDiff / DAY);
		if (days === 1) {
			return `${days} day${suffix}`;
		}
		return `${days} days${suffix}`;
	}
	if (dateDiff < MONTH) {
		const weeks = Math.floor(dateDiff / WEEK);
		if (weeks === 1) {
			return `${weeks} week${suffix}`;
		}
		return `${weeks} weeks${suffix}`;
	}
	if (dateDiff < YEAR) {
		const months = Math.floor(dateDiff / MONTH);
		if (months === 1) {
			return `${months} month${suffix}`;
		}
		return `${months} months${suffix}`;
	}
	if (dateDiff >= YEAR) {
		const years = Math.floor(dateDiff / YEAR);
		if (years === 1) {
			return `${years} year${suffix}`;
		}
		return `${years} years${suffix}`;
	}
	return 'invalid date';
}
