import React, { ChangeEvent, useState } from 'react';
import { Value } from 'slate';
import PropTypes from 'prop-types';
import SlateEditor from '/client/app/components/common/inputs/slateEditor/slateEditor';
import htmlSerializer from '/client/app/components/common/inputs/slateEditor/htmlSerializer';
import Hideable from '/client/app/components/common/hideable';
import Input from '/client/app/components/common/inputs/input/input';
import Plain from 'slate-plain-serializer';

require('./communityRuleEditor.scss');

interface IProps {
	editable: boolean;
	isCreatingNew: boolean;
	title: string;
	index: number;
	detailedDescriptionHTML: string;
	onSave: (title: string, detailedDescription: string, detailedDescriptionHTML: string) => Promise<void> | void;
	onCancelCreateNew: () => void;
	onDelete: () => Promise<void> | void;
}

export default function CommunityRuleEditor(props: IProps) {
	const { editable, title, detailedDescriptionHTML, onSave, isCreatingNew, onCancelCreateNew, onDelete, index } = props;

	const [titleInputValue, setTitleInputValue] = useState(title);
	const [editing, setEditing] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>();

	async function saveChanges(value: Value) {
		const html = htmlSerializer.serialize(value);
		const plaintext = Plain.serialize(value);
		try {
			await onSave(titleInputValue, plaintext, html);
		} catch (error) {
			setErrorMessage(error.message);
		}
		setEditing(false);
		if (isCreatingNew) onCancelCreateNew();
	}

	function cancelEdit() {
		setTitleInputValue(title);
		setEditing(false);
	}

	const classNames = ['communityRuleEditor'];
	if (editable) classNames.push('editable');

	return (
		<div className={classNames.join(' ')}>
			<div className="title">
				<Hideable hidden={editing || isCreatingNew}>{`${index}. ${title}`}</Hideable>
				<Hideable hidden={!editing && !isCreatingNew}>
					<div className="editableRuleTitle">
						<span>{index}.&nbsp;</span>
						<Input
							placeholder="rule title"
							name="rule title input"
							className="commonInput titleInput"
							value={titleInputValue}
							autoFocus={isCreatingNew}
							onInput={(e: ChangeEvent<HTMLInputElement>) => setTitleInputValue(e.target.value)}
						/>
					</div>
				</Hideable>
			</div>
			<SlateEditor
				onSave={saveChanges}
				onEditCancel={isCreatingNew ? onCancelCreateNew : cancelEdit}
				onEdit={() => setEditing(true)}
				onDelete={isCreatingNew ? undefined : onDelete}
				displayOnly
				editOnMount={isCreatingNew}
				editable={editable}
				showEditableBorder={false}
				placeholder="detailed description"
				initialValue={htmlSerializer.deserialize(detailedDescriptionHTML)}
			/>
			<Hideable hidden={!editing || !errorMessage}>Error: {errorMessage}</Hideable>
		</div>
	);
}

CommunityRuleEditor.propTypes = {
	editable: PropTypes.bool,
	isCreatingNew: PropTypes.bool,
	title: PropTypes.string.isRequired,
	detailedDescriptionHTML: PropTypes.string.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancelCreateNew: PropTypes.func,
	onDelete: PropTypes.func,
	index: PropTypes.number.isRequired,
};

CommunityRuleEditor.defaultProps = {
	editable: false,
	isCreatingNew: false,
	onCancelCreateNew: undefined,
	onDelete: undefined,
};
