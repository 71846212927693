import gql from 'graphql-tag';

export const CREATE_COMMUNITY_RULE = gql`
	mutation createCommunityRule(
		$communityId: String
		$communityName: String
		$title: String!
		$detailedDescription: String!
		$detailedDescriptionHTML: String!
	) {
		createCommunityRule(
			communityId: $communityId
			communityName: $communityName
			title: $title
			detailedDescription: $detailedDescription
			detailedDescriptionHTML: $detailedDescriptionHTML
		) {
			_id
			community {
				_id
				moderators {
					_id
				}
				name
				creator {
					_id
					username
				}
				owner {
					_id
					username
				}
			}
			title
			detailedDescription
			detailedDescriptionHTML
		}
	}
`;

export const UPDATE_COMMUNITY_RULE = gql`
	mutation updateCommmunityRule(
		$_id: String!
		$title: String!
		$detailedDescription: String!
		$detailedDescriptionHTML: String!
	) {
		updateCommmunityRule(
			_id: $_id
			title: $title
			detailedDescription: $detailedDescription
			detailedDescriptionHTML: $detailedDescriptionHTML
		) {
			_id
			title
			detailedDescription
			detailedDescriptionHTML
		}
	}
`;

export const DELETE_COMMUNITY_RULE = gql`
	mutation deleteCommunityRule($_id: String!) {
		deleteCommunityRule(_id: $_id)
	}
`;
