import React from 'react';
import PropTypes from 'prop-types';
import SubSidebarContainer from '/client/app/components/communitySidebar/subSidebarContainer';
import EndlessContentDiscoveryWidget from '/client/app/components/endlessContentDiscoveryWidget/endlessContentDiscoveryWidget';

export default function MultiSubSidebarContainer(props: any) {
	const { subNames, ssr } = props;

	return (
		<div className="sidebar">
			{subNames.map((subName: string) => (
				<SubSidebarContainer ssr={ssr} subName={subName} key={`${subName}sidebar`} />
			))}
			<EndlessContentDiscoveryWidget />
		</div>
	);
}

MultiSubSidebarContainer.propTypes = {
	subNames: PropTypes.arrayOf(PropTypes.string),
	ssr: PropTypes.bool,
};
MultiSubSidebarContainer.defaultProps = {
	subNames: [],
	ssr: true,
};
