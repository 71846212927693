import gql from 'graphql-tag';

export const TIP_COMMENT = gql`
	mutation tipComment($commentId: String!) {
		tipComment(commentId: $commentId) {
			userAccount {
				_id
				balance
			}
			comment {
				_id
				tips
			}
		}
	}
`;

export const TIP_POST = gql`
	mutation tipPost($postId: String!) {
		tipPost(postId: $postId) {
			userAccount {
				_id
				balance
			}
			post {
				_id
				tips
			}
		}
	}
`;

export const SAVE_POST = gql`
	mutation savePost($postId: String!) {
		savePost(postId: $postId) {
			_id
			savedItems {
				posts {
					_id
				}
			}
		}
	}
`;

export const UNSAVE_POST = gql`
	mutation unsavePost($postId: String!) {
		unsavePost(postId: $postId) {
			_id
			savedItems {
				posts {
					_id
				}
			}
		}
	}
`;

export const SAVE_COMMENT = gql`
	mutation saveComment($commentId: String!) {
		saveComment(commentId: $commentId) {
			_id
			savedItems {
				comments {
					_id
				}
			}
		}
	}
`;

export const UNSAVE_COMMENT = gql`
	mutation unsaveComment($commentId: String!) {
		unsaveComment(commentId: $commentId) {
			_id
			savedItems {
				comments {
					_id
				}
			}
		}
	}
`;

export const DELETE_COMMENT = gql`
	mutation deleteComment($commentId: String!) {
		deleteComment(commentId: $commentId) {
			_id
			deleted
			deletionDate
		}
	}
`;

export const SUBSCRIBE_TO_SUB = gql`
	mutation subscribeToSub($subId: String!) {
		subscribeToSub(subId: $subId) {
			_id
			subscriptions {
				subs {
					_id
					subscribers
				}
			}
		}
	}
`;

export const UNSUBSCRIBE_FROM_SUB = gql`
	mutation unsubscribeFromSub($subId: String!) {
		unsubscribeFromSub(subId: $subId) {
			_id
			subscriptions {
				subs {
					_id
					subscribers
				}
			}
		}
	}
`;

export const SUBSCRIBE_TO_USER = gql`
	mutation subscribeToUser($userId: String, $username: String) {
		subscribeToUser(userId: $userId, username: $username) {
			_id
			subscriptions {
				users {
					_id
					subscribers
					username
					usernameLowercase
				}
			}
		}
	}
`;

export const UNSUBSCRIBE_FROM_USER = gql`
	mutation unsubscribeFromUser($userId: String, $username: String) {
		unsubscribeFromUser(userId: $userId, username: $username) {
			_id
			subscriptions {
				users {
					_id
					subscribers
					username
					usernameLowercase
				}
			}
		}
	}
`;

export const CREATE_COMMENT = gql`
	mutation createComment($body: String!, $bodyHTML: String!, $postId: String!, $parentCommentId: String) {
		createComment(body: $body, bodyHTML: $bodyHTML, postId: $postId, parentCommentId: $parentCommentId) {
			_id
			bodyHTML
			creationDate
			tips
			edited
			editDate
			account {
				_id
				balance
			}
			user {
				_id
				username
				account {
					_id
					balance
				}
				totalInEscrow
			}
			children {
				_id
			}
			parentComment {
				_id
				children {
					_id
				}
			}
			sub {
				_id
				actionCost
			}
			post {
				_id
				commentCount
				comments {
					_id
				}
			}
		}
	}
`;

export const EDIT_COMMENT = gql`
	mutation editComment($commentId: String!, $body: String!, $bodyHTML: String!) {
		editComment(commentId: $commentId, body: $body, bodyHTML: $bodyHTML) {
			_id
			bodyHTML
			edited
			editDate
		}
	}
`;

export const EDIT_SIDEBAR_TEXT = gql`
	mutation setSidebarText($name: String!, $sidebarText: JSONObject!) {
		setSidebarText(name: $name, sidebarText: $sidebarText) {
			_id
			sidebarText
		}
	}
`;

export const CREATE_MONEY = gql`
	mutation createMoney($amount: Int!) {
		createMoney(amount: $amount) {
			_id
			balance
		}
	}
`;
