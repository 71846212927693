import React from 'react';
import PropTypes from 'prop-types';
import { UPDATE_COMMUNITY_RULE, DELETE_COMMUNITY_RULE } from '/client/app/graphql/mutations/communityRules';
import { useMutation } from '@apollo/react-hooks';
import CommunityRuleEditor from './communityRuleEditor';

interface IProps {
	ruleId: string;
	title: string;
	detailedDescriptionHTML: string;
	editable: boolean;
	onCancelCreateNew: () => void;
	refresh: () => Promise<void> | void;
	index: number;
}

export default function CommunityRuleEditorContainer(props: IProps) {
	const { editable, ruleId, title, detailedDescriptionHTML, onCancelCreateNew, refresh, index } = props;

	const [updateCommunityRuleMutation] = useMutation(UPDATE_COMMUNITY_RULE);
	const [deleteCommunityRuleMutation] = useMutation(DELETE_COMMUNITY_RULE);

	async function updateCommunityRule(newTitle: string, detailedDescription: string, newDetailedDescriptionHTML: string) {
		await updateCommunityRuleMutation({
			variables: {
				_id: ruleId,
				title: newTitle,
				detailedDescription,
				detailedDescriptionHTML: newDetailedDescriptionHTML,
			},
		});
	}

	async function deleteCommunityRule() {
		await deleteCommunityRuleMutation({
			variables: {
				_id: ruleId,
			},
		});
		await refresh();
	}

	return (
		<CommunityRuleEditor
			onDelete={deleteCommunityRule}
			isCreatingNew={false}
			title={title}
			detailedDescriptionHTML={detailedDescriptionHTML}
			onSave={updateCommunityRule}
			onCancelCreateNew={onCancelCreateNew}
			editable={editable}
			index={index}
		/>
	);
}

CommunityRuleEditorContainer.propTypes = {
	ruleId: PropTypes.string.isRequired,
	editable: PropTypes.bool,
	onCancelCreateNew: PropTypes.func,
	refresh: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
};

CommunityRuleEditorContainer.defaultProps = {
	editable: false,
	onCancelCreateNew: undefined,
};
