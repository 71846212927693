import React from 'react';
import SlateEditor from '/client/app/components/common/inputs/slateEditor/slateEditor';
import { Value } from 'slate';
import { Link } from 'react-router-dom';
import getTimeDelta from '/client/app/util/dateUtils';
import Hideable from '/client/app/components/common/hideable';
import CommunityRuleListContainer from '/client/app/components/communityRuleList/communityRuleListContainer';

require('./sidebar.scss');

export default function SubSidebar(props: any) {
	const { data, userActions, loading } = props;
	const { subscribe, unsubscribe } = userActions;
	const { modifyingSubscription, setSidebarText, subName } = props;

	const _id = data?.subByName?._id || '';
	const title = data?.subByName?.title || '';
	const name = data?.subByName?.name || null;
	const creationDate = data?.subByName?.creationDate || new Date();
	const sidebarText = data?.subByName?.sidebarText || '';
	const moderators = data?.subByName?.moderators || [];
	const subscribers = data?.subByName?.subscribers || 0;

	let subscribedSubs;
	let isLoggedIn = false;
	let isSubscribed = false;
	let isAdmin = false;
	let isModerator = false;

	if (data?.sessionUser) {
		subscribedSubs = props.data.sessionUser.subscriptions.subs;
		isSubscribed = subscribedSubs.find((sub: any) => sub._id === _id);
		isLoggedIn = true;
		isAdmin = !!data.sessionUser.memberOf.find((group: any) => group.name === 'Site Admins' && group.builtin);
		isModerator = !!moderators.find((moderator: any) => moderator.username === data.sessionUser.username);
	}

	async function handleSubscription() {
		if (isSubscribed) {
			await unsubscribe({ variables: { subId: _id } });
		} else await subscribe({ variables: { subId: _id } });
		isSubscribed = !isSubscribed;
	}

	const subscribeButtonClassNames = ['tinyButton', 'subscribeButton'];
	if (modifyingSubscription) subscribeButtonClassNames.push('pulsing');

	const timeDelta = getTimeDelta(new Date(creationDate), '');

	function getModerators() {
		return moderators.map((moderator: any) => {
			let truncatedUsername = moderator.username;
			if (truncatedUsername.length > 26) truncatedUsername = `${truncatedUsername.substring(0, 23)}...`;
			return (
				<div className="moderator" key={`sidebar.moderator.${moderator.username}`}>
					<Link title={moderator.username} to={{ pathname: `/u/${moderator.username}` }}>
						{truncatedUsername}
					</Link>
				</div>
			);
		});
	}

	function saveSidebarText(value: any) {
		setSidebarText(value);
	}

	return (
		<div className="sidebarSection">
			<div className="sidebarTitle">
				<Link to={`/s/${name}`}>{title}</Link>
			</div>
			<div className="sidebarSubSection">
				<button
					type="button"
					disabled={modifyingSubscription || !isLoggedIn || !data}
					className={subscribeButtonClassNames.join(' ')}
					onClick={handleSubscription}
				>
					{isSubscribed ? 'unsubscribe' : 'subscribe'}
				</button>
				<p className="subscriberCount">
					{`${subscribers} subscriber`}
					<Hideable hidden={subscribers === 1}>s</Hideable>
				</p>
				<p className="communityAge">{`community for ${timeDelta}`}</p>
				<div className="sidebarSubtext">
					<Hideable hidden={loading}>
						{() => (
							<SlateEditor
								onSave={saveSidebarText}
								displayOnly
								editable={isModerator || isAdmin}
								initialValue={sidebarText ? Value.fromJSON(sidebarText) : undefined}
							/>
						)}
					</Hideable>
				</div>
				<div className="rules">
					<CommunityRuleListContainer editable={isModerator || isAdmin} communityName={name || subName} />
				</div>
				<div className="sidebarModerators">
					moderated by
					{getModerators()}
				</div>
			</div>
		</div>
	);
}
