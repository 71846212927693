import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import LoadingWrapper from '../../components/loading/loadingWrapper';
import PostView from './post';
import { SAVE_POST, TIP_POST, UNSAVE_POST } from '../../graphql/userActions';
import useCachedQuery from '/client/app/graphql/useCachedQuery';

import '/client/app/styles/error.scss';

export const GET_POST = gql`
	query post($postId: String!) {
		post(_id: $postId) {
			_id
			thumbnail {
				_id
				path
			}
			embed {
				_id
				thumbnailURL
				html
				compactHTML
			}
			media {
				_id
				path
			}
			text
			textHTML
			tips
			title
			creationDate
			commentCount
			url
			urlTitle
			sub {
				_id
				name
				actionCost
			}
			user {
				_id
				username
				memberOf {
					_id
					name
					builtin
				}
			}
		}
		sessionUser {
			_id
			savedItems {
				posts {
					_id
				}
			}
		}
	}
`;

export default function PostContainer(props) {
	const { urlParameters } = props;
	const { postId, commentId } = urlParameters;
	const { data, error, loading } = useCachedQuery(GET_POST, { variables: { postId }, errorPolicy: 'ignore' });
	const [tipPost] = useMutation(TIP_POST);
	const [savePost] = useMutation(SAVE_POST);
	const [unsavePost] = useMutation(UNSAVE_POST);
	const userActions = {
		tipPost,
		savePost,
		unsavePost,
	};

	function renderError() {
		return <div className="errorLabelMedium">{error?.message || 'no such post'}</div>;
	}

	function renderPost() {
		return <PostView data={data || {}} commentId={commentId} userActions={userActions} />;
	}

	return (
		<LoadingWrapper width="100%" height="3em" loading={loading}>
			{() => (error || !data?.post ? renderError() : renderPost())}
		</LoadingWrapper>
	);
}

PostContainer.propTypes = {
	urlParameters: PropTypes.shape({
		postId: PropTypes.string,
		commentId: PropTypes.string,
	}).isRequired,
};
