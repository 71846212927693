import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '/client/app/styles/controls.scss';
import '/client/app/styles/text.scss';
import './post.scss';
import CommentEditorContainer from '/client/app/components/commentEditor/commentEditorContainer';
import PostComments from '/client/app/views/post/postComments';
import Hideable from '/client/app/components/common/hideable';
import CommentContainer from '/client/app/components/comment/commentContainer';
import RadioButtonGroup from '/client/app/components/common/inputs/radioButton/radioButtonGroup';
import CollapsingSidebarLayout from '/client/app/components/layouts/collapsingSidebarLayout/collapsingSidebarLayout';
import PostSidebar from '/client/app/components/postSidebar/postSidebar';
import CardFeedItem from '/client/app/components/feed/cardFeedItem/cardFeedItem';

export default function PostView(props) {
	const { data, userActions, commentId } = props;
	const {
		post: {
			_id,
			title,
			commentCount,
			sub: { name: communityName, actionCost, _id: communityId },
			user: { username },
		},
	} = data;

	const [sortOrder, setSortOrder] = useState('top');

	useEffect(() => {
		document.title = `pfyt - ${title}`;

		return function cleanup() {
			document.title = 'pfyt';
		};
	});

	let truncatedUsername = username;
	if (truncatedUsername.length > 26) {
		truncatedUsername = `${truncatedUsername.substring(0, 23)}...`;
	}

	return (
		<>
			<div className="mainTitle">
				<Link to={`/s/${communityName}`}>{communityName}</Link>
				{` / ${title}`}
			</div>
			<CollapsingSidebarLayout>
				<div className="postViewContainer">
					<div className="postViewTop">
						<div className="postImageAndText">
							<CardFeedItem postData={data} hideTitle hideCommentsLink userActions={userActions} />
						</div>
						<Hideable hidden={!!commentId}>
							<div className="postReplySection">
								<CommentEditorContainer actionCost={actionCost} postId={_id} communityId={communityId} />
							</div>
						</Hideable>
					</div>
					<div className="postComments">
						<Hideable hidden={!commentId}>
							<div className="partialCommentChainAlert">
								<FontAwesomeIcon icon={faExclamation} className="alertIcon" />
								<div>
									this is a single comment&apos;s thread
									<br />
									<Link to={`/s/${communityName}/comments/${_id}`}>view all comments</Link>
								</div>
							</div>
						</Hideable>
						<div className="commentViewOptionsSection">
							<Hideable hidden={!!commentId}>
								<span className="commentViewOptionsLabel">{commentCount} comments</span>
								<span className="commentViewOptionsSeparator" />
							</Hideable>
							<span className="commentViewOptionsLabel">sort by</span>
							<RadioButtonGroup
								name="commentSortToggle"
								buttons
								horizontal
								options={[
									{ id: 'top', value: 'top', label: 'top' },
									{ id: 'new', value: 'new', label: 'new' },
								]}
								defaultOptionId="top"
								onChange={e => setSortOrder(e.target.value)}
							/>
						</div>
						<Hideable hidden={!!commentId}>
							<PostComments sortOrder={sortOrder} postId={_id} />
						</Hideable>
						<Hideable hidden={!commentId}>
							{() => <CommentContainer sortOrder={sortOrder} key="singleViewCommentContainer" commentId={commentId} />}
						</Hideable>
					</div>
				</div>
				<PostSidebar communityName={communityName} />
			</CollapsingSidebarLayout>
		</>
	);
}

PostView.propTypes = {
	data: PropTypes.shape({
		post: PropTypes.shape({
			_id: PropTypes.string,
			thumbnail: PropTypes.shape({
				path: PropTypes.string,
			}),
			embed: PropTypes.shape({
				thumbnailURL: PropTypes.string,
				html: PropTypes.string,
			}),
			media: PropTypes.arrayOf(
				PropTypes.shape({
					path: PropTypes.string,
				})
			),
			text: PropTypes.string,
			textHTML: PropTypes.string,
			tips: PropTypes.number,
			title: PropTypes.string,
			creationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
			comments: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
			commentCount: PropTypes.number,
			sub: PropTypes.shape({
				_id: PropTypes.string,
				name: PropTypes.string,
				actionCost: PropTypes.number,
			}),
			user: PropTypes.shape({
				_id: PropTypes.string,
				username: PropTypes.string,
				memberOf: PropTypes.arrayOf(
					PropTypes.shape({
						_id: PropTypes.string,
						name: PropTypes.string,
						builtin: PropTypes.bool,
					})
				),
			}),
			url: PropTypes.string,
		}),
		sessionUser: PropTypes.shape({
			savedItems: PropTypes.shape({
				posts: PropTypes.arrayOf(
					PropTypes.shape({
						_id: PropTypes.string,
					})
				),
			}),
		}),
	}).isRequired,
	userActions: PropTypes.shape({
		tipPost: PropTypes.func,
		savePost: PropTypes.func,
		unsavePost: PropTypes.func,
	}).isRequired,
	commentId: PropTypes.string,
};

PostView.defaultProps = {
	commentId: undefined,
};
