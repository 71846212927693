import React from 'react';
import PropTypes from 'prop-types';

import { COMMUNITY_RULES_BY_COMMUNITY_NAME } from '/client/app/graphql/queries/communityRules';

import CommunityRuleList from './communityRuleList';
import { useMutation, useQuery } from '@apollo/react-hooks';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import { CREATE_COMMUNITY_RULE } from '/client/app/graphql/mutations/communityRules';

interface IProps {
	communityName: string;
	editable: boolean;
}

export default function CommunityRuleListContainer(props: IProps) {
	const { communityName, editable } = props;

	const { data, loading, refetch } = useQuery(COMMUNITY_RULES_BY_COMMUNITY_NAME, {
		variables: {
			communityName,
		},
		errorPolicy: 'all',
	});

	const [createCommmunityRuleMutation] = useMutation(CREATE_COMMUNITY_RULE);

	const rules = data?.communityRulesByCommunityName || [];

	async function createCommunityRule(title: string, detailedDescription: string, detailedDescriptionHTML: string) {
		await createCommmunityRuleMutation({
			variables: {
				communityName,
				title,
				detailedDescription,
				detailedDescriptionHTML,
			},
		});
	}

	async function refresh() {
		await refetch({ communityName });
	}

	return (
		<LoadingWrapper width="100%" height="3em" loading={loading}>
			<CommunityRuleList
				siteWide={!communityName}
				refresh={refresh}
				createRule={createCommunityRule}
				rules={rules}
				editable={editable}
			/>
		</LoadingWrapper>
	);
}

CommunityRuleListContainer.propTypes = {
	communityName: PropTypes.string,
	editable: PropTypes.bool,
};

CommunityRuleListContainer.defaultProps = {
	communityName: undefined,
	editable: false,
};
