import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CommunityRuleEditorContainer from '/client/app/components/communityRuleEditor/communityRuleEditorContainer';
import CommunityRuleEditor from '/client/app/components/communityRuleEditor/communityRuleEditor';
import Hideable from '/client/app/components/common/hideable';

require('./communityRuleList.scss');

interface IProps {
	editable: boolean;
	siteWide: boolean;
	createRule: (title: string, detailedDescription: string, detailedDescriptionHTML: string) => Promise<void> | void;
	refresh: () => Promise<void> | void;
	rules: {
		_id: string;
		title: string;
		detailedDescriptionHTML: string;
	}[];
}

export default function CommunityRuleList(props: IProps) {
	const { editable, rules, createRule, refresh, siteWide } = props;
	const [addingNew, setAddingNew] = useState(false);

	function getListItems() {
		return rules.map((rule: any, index: number) => (
			<CommunityRuleEditorContainer
				refresh={refresh}
				title={rule.title}
				detailedDescriptionHTML={rule.detailedDescriptionHTML}
				ruleId={rule._id}
				editable={editable}
				index={index + 1}
			/>
		));
	}

	async function createAndRefresh(title: string, detailedDescription: string, detailedDescriptionHTML: string) {
		await createRule(title, detailedDescription, detailedDescriptionHTML);
		await refresh();
	}

	return (
		<div className="communityRuleList">
			<span className="title">{siteWide ? 'site rules' : 'rules'}</span>
			<Hideable hidden={rules.length > 0 || addingNew}>
				<div>this {siteWide ? 'site' : 'community'} has no rules</div>
			</Hideable>
			<div className="rules">
				{getListItems()}
				<Hideable hidden={!addingNew}>
					<CommunityRuleEditor
						isCreatingNew
						title=""
						detailedDescriptionHTML="<p/>"
						onSave={createAndRefresh}
						editable
						onCancelCreateNew={() => setAddingNew(false)}
						index={rules.length + 1}
					/>
				</Hideable>
				<Hideable hidden={!editable}>
					<button
						type="button"
						onClick={() => setAddingNew(!addingNew)}
						name={addingNew ? 'cancel' : 'add rule'}
						className={'tinyButton'}
					>
						{addingNew ? 'cancel' : 'add rule'}
					</button>
				</Hideable>
			</div>
		</div>
	);
}

CommunityRuleList.propTypes = {
	editable: PropTypes.bool,
	siteWide: PropTypes.bool,
	createRule: PropTypes.func.isRequired,
	refresh: PropTypes.func.isRequired,
	rules: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
			title: PropTypes.string,
			detailedDescriptionHTML: PropTypes.string,
		})
	),
};

CommunityRuleList.defaultProps = {
	editable: false,
	siteWide: false,
	rules: [],
};
