import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { SUBSCRIBE_TO_SUB, UNSUBSCRIBE_FROM_SUB, EDIT_SIDEBAR_TEXT } from '/client/app/graphql/userActions';
import SubSidebar from '/client/app/components/communitySidebar/subSidebar';
import useCachedQuery from '/client/app/graphql/useCachedQuery';

const GET_SIDEBAR_DATA = gql`
	query($subName: String!) {
		subByName(name: $subName) {
			_id
			actionCost
			name
			creator {
				_id
				username
			}
			moderators {
				_id
				username
			}
			owner {
				_id
				username
			}
			description
			sidebarText
			title
			subscribers
			creationDate
		}
		sessionUser {
			_id
			username
			email
			account {
				_id
				balance
			}
			escrowAccount {
				_id
				balance
			}
			totalInEscrow
			memberOf {
				_id
				name
				builtin
			}
			memberOf {
				builtin
				name
			}
			subscriptions {
				subs {
					_id
					subscribers
				}
			}
		}
	}
`;
const UPDATE_MUTATED_DATA = gql`
	query($subName: String!) {
		subByName(name: $subName) {
			_id
			subscribers
		}
	}
`;

export default function SubSidebarContainer(props: any) {
	const { subName, ssr } = props;
	const { data, loading } = useCachedQuery(GET_SIDEBAR_DATA, { variables: { subName }, ssr });
	const [subscribe, { loading: subscribing }] = useMutation(SUBSCRIBE_TO_SUB);
	const [setSidebarText] = useMutation(EDIT_SIDEBAR_TEXT);
	const [unsubscribe, { loading: unsubscribing }] = useMutation(UNSUBSCRIBE_FROM_SUB, {
		refetchQueries: [{ query: UPDATE_MUTATED_DATA, variables: { subName } }],
	});
	const userActions = {
		subscribe,
		unsubscribe,
	};
	const inProgress = subscribing || unsubscribing;

	async function doSetSidebarText(value: any) {
		await setSidebarText({ variables: { sidebarText: value, name: data.subByName.name } });
	}

	return (
		<SubSidebar
			setSidebarText={doSetSidebarText}
			loading={loading}
			subName={subName}
			data={data}
			modifyingSubscription={inProgress}
			userActions={userActions}
		/>
	);
}

SubSidebarContainer.propTypes = {
	subName: PropTypes.string.isRequired,
	ssr: PropTypes.bool,
};

SubSidebarContainer.defaultProps = {
	ssr: true,
};
