import React from 'react';

require('./collapsingSidebarLayout.scss');

interface IProps {
	children: any[];
}

export default function CollapsingSidebarLayout(props: IProps) {
	const { children } = props;

	return <div className="collapsingSidebarLayout">{children}</div>;
}
