import gql from 'graphql-tag';

export const COMMUNITY_RULE = gql`
	query communityRule($_id: String!) {
		communityRule(_id: $_id) {
			_id
			creator {
				_id
				username
			}
			community {
				_id
				moderators {
					_id
				}
				name
				creator {
					_id
					username
				}
				owner {
					_id
					username
				}
			}
			title
			detailedDescription
			detailedDescriptionHTML
			siteWide
			creationDate
		}
	}
`;

export const COMMUNITY_RULES = gql`
	query communityRules($communityId: String) {
		communityRules(communityId: $communityId) {
			_id
			creator {
				_id
				username
			}
			community {
				_id
				moderators {
					_id
				}
				name
				creator {
					_id
					username
				}
				owner {
					_id
					username
				}
			}
			title
			detailedDescription
			detailedDescriptionHTML
			siteWide
			creationDate
		}
	}
`;

export const COMMUNITY_RULES_BY_COMMUNITY_NAME = gql`
	query communityRulesByCommunityName($communityName: String) {
		communityRulesByCommunityName(communityName: $communityName) {
			_id
			creator {
				_id
				username
			}
			community {
				_id
				moderators {
					_id
				}
				name
				creator {
					_id
					username
				}
				owner {
					_id
					username
				}
			}
			title
			detailedDescription
			detailedDescriptionHTML
			siteWide
			creationDate
		}
	}
`;
